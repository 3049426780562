import React, { useRef } from "react";
import SEO from "../../components/seo";
import Layout from "../../components/layout";
import ServiceHero from "../../modules/hero/service-hero";
import TwoColumnText from "../../components/two-column-text";
import FeaturedWork from "../../components/featured-work";
import FooterMapDetails from "../../components/footer/footer-map-details";
import Blitz from "../../images/services/Case-Studies-Blitz.jpeg";
import Sricles from "../../images/services/Case-Studies-Sircles.jpeg";

const featured = [
  {
    title: "Sircles",
    coverImage: Sricles,
    link: "sircles",
  },
  {
    title: "Blitz App",
    coverImage: Blitz,
    link: "blitz",
  },
];

const Description = () => {
  return (
    <div>
      <p>
        <b>Requirements Gathering</b>
      </p>
      <p>
        Requirements gathering is the most important part and the initial point
        in staring a partnership. We need to understand your problems and pains
        so we know how we can help with a detailed plan.
      </p>
      <p>
        <b>Scoping Work</b>
      </p>
      <p>
        Everyone has deadlines, we try to fit our development within your
        deadlines. We believe in the agile methodology, we will always start
        with an MVP and then build that out part by part with user testing all
        along the way.
      </p>
      <p>
        <b>Over-delivery</b>
      </p>
      <p>
        We believe in over delivering in all aspects. From quality to deadlines
        to the way we communicate. All of our clients say this is what they love
        most about us.
      </p>
      <p>
        <b>Maintenaince</b>
      </p>
      <p>
        We believe a partership should go further than just the scope of work
        that is decided on. We want to maintain our relationship with all of our
        clients even after the work is finished both on a personal level and on
        a code / infrastructure level.
      </p>
    </div>
  );
};

const ServiceTemplate = ({ location }) => {
  const pageLayoutRef = useRef();

  const footerDate = {
    location: location,
    menu: [
      {
        text: "Start a project",
        link: "/start-a-project",
      },
      {
        text: "Our Services",
        link: "/services",
      },
      {
        text: "Our Work",
        link: "/work",
      },
    ],
    description: `
    We define and develop solutions carefully, skillfully, and beautifully. Powered by a solid team of talented professionals, we collaborate globally to craft the best solutions for you.`,
  };

  const seo = {
    pageUrl: "/services/web-and-mobile-development",
    pageTitle:
      "Web and mobile development | Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription:
      "Web and mobile development | Vello | A full-service creative agency.",
  };

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <ServiceHero
          title={"Web & Mobile Development"}
          pageLayoutRef={pageLayoutRef}
        />
        <TwoColumnText
          title="Overview"
          text={
            "Our engagement model is iterative and collaborative, not just bringing you along but working together to create work that delivers results. We think about long-term opportunities while helping you solve the immediate needs of today. From KPI definition and experience vision to rapid design, prototyping, and deployment, we’ve built our agency to be a true partner for our clients and we’d love to work with you to create what’s next."
          }
        />
        <TwoColumnText title="Our Process">
          <Description />
        </TwoColumnText>
        <FeaturedWork
          title="Featured Work"
          featuredWork={featured}
          pageLayoutRef={pageLayoutRef}
        />

        <FooterMapDetails {...footerDate} pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default ServiceTemplate;
